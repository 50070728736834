import { Alert } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import React, { useContext } from 'react';
import cn from 'classnames';
import { AuthContext } from '@contexts/AuthContext';
import { isBlank } from '@util/StringUtil';

type Props = { className?: string };
const LoginContextMessage = (props: Props) => {
    const authContext = useContext(AuthContext);
    const message = authContext.message?.message;
    const severity = authContext.message?.severity ?? 'info';
    const closeable = authContext.message?.closeable ?? false;
    if (!message || isBlank(message)) {
        return null;
    }

    const handleClose = () => {
        authContext.setMessage(null);
    };

    return (
        <Alert
            severity={severity}
            className={cn(props.className, 'whitespace-pre-line')}
            onClose={closeable ? handleClose : undefined}
        >
            <ReactMarkdown>{message}</ReactMarkdown>
        </Alert>
    );
};

export default LoginContextMessage;
