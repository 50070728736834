import { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
    form: ReactNode;
};

const FancyRegistration = ({ form }: Props) => {
    return (
        <div className="flex h-full min-h-screen flex-grow lg:flex-row lg:bg-white">
            {/* Left Column */}
            <div className="flex flex-1 flex-col py-8 md:py-12 lg:py-0 lg:self-center">
                <div className="mx-auto max-w-lg rounded-2xl bg-white lg:bg-transparent px-4 sm:px-6 md:px-8">
                    {form}
                </div>
            </div>
            {/* Right Column (Hidden on Mobile) */}
            <div
                className="hidden lg:flex flex-1 items-center justify-center "
                style={{
                    backgroundImage: "url('/images/backgrounds/hero-background1.png')",
                }}
            >
                <Link href="https://pluto.bio">
                    <Image
                        src="/images/logos/Pluto_Bio_Logo_white.png"
                        width={400}
                        height={127}
                        className="mx-auto"
                        alt="Pluto Bio Logo"
                    />
                </Link>
            </div>
        </div>
    );
};

export default FancyRegistration;
