import LoginForm from '@components/LoginForm';
import { getDefaultLayout, PageLayout } from '@layouts/Layout';
import LoginContextMessage from '@components/LoginContextMessage';
import FancyRegistration from '@components/FancyRegistration';
import SamlLoginBanner from '@components/SamlLoginBanner';
import { Link } from '@mui/material';
import useInviteHref from '@hooks/useInviteHref';
import useInviteValidation from '@hooks/useInviteValidation'; // Update the path accordingly

const LoginPage: PageLayout = () => {
    const signupHrefAppended = useInviteHref('signup');
    const hasInviteId = useInviteValidation(); //uses router to get querystring
    return (
        <FancyRegistration
            form={
                <>
                    <LoginContextMessage className="mb-8" />
                    <SamlLoginBanner className="mb-4 md:mb-8" />
                    <div className="mb-8 space-y-2">
                        <h3 className="text-center text-3xl font-semibold tracking-tight">Welcome back!</h3>
                        <p className="text-center">
                            Log in to your account to view the latest experiments and&nbsp;updates<br></br>
                            {hasInviteId && (
                                <span>
                                    New to Pluto?&nbsp;
                                    <Link href={signupHrefAppended} variant="body2">
                                        Create an account
                                    </Link>
                                    .
                                </span>
                            )}
                        </p>
                    </div>
                    <LoginForm />
                </>
            }
        />
    );
};

LoginPage.getLayout = getDefaultLayout({
    noMargin: true,
    container: false,
    title: 'Log In to Pluto Bio',
    description: 'Log in to the Pluto app',
});

export default LoginPage;
