import React, { useEffect, useState } from 'react';
import { SamlStatusItem } from '@models/SSOSettings';
import LocalStorageService, { StorageKey } from '@util/LocalStorageService';
import cn from 'classnames';
import { InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import NoSsr from '@components/NoSsr';
import NextLink from 'next/link';
import { IconButton } from '@mui/material';

type Props = { className?: string };
const SamlLoginBanner = ({ className }: Props) => {
    const [samlConfig, setSamlConfig] = useState<SamlStatusItem | null>(null);
    useEffect(() => {
        const config = LocalStorageService.getObject<SamlStatusItem>(StorageKey.SAML_CONFIG);
        setSamlConfig(config ?? null);
    }, []);

    if (!samlConfig) {
        return null;
    }

    const handleCancel = () => {
        LocalStorageService.removeItem(StorageKey.SAML_CONFIG);
        setSamlConfig(null);
    };

    return (
        <NoSsr>
            <div className={cn('relative flex items-start space-x-2 rounded bg-success p-4', className)}>
                <InformationCircleIcon width={18} className="mt-0.5 flex-shrink-0 text-success" />
                <div className="flex w-full flex-col">
                    <p className="pr-6 font-semibold text-dark">
                        Did you mean to log in with {samlConfig.org_name} SSO?
                    </p>
                    <p className="">
                        <NextLink href={`/login/${samlConfig.slug}`} passHref legacyBehavior>
                            <a className="whitespace-nowrap">Go to sign-in page</a>
                        </NextLink>
                    </p>
                </div>
                <span className="absolute right-0 top-0">
                    <IconButton
                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                        onClick={handleCancel}
                        size="medium"
                    >
                        <XIcon width={18} />
                    </IconButton>
                </span>
            </div>
        </NoSsr>
    );
};

export default SamlLoginBanner;
